/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Select from 'react-select';
// import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
// import 'ag-grid-enterprise';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import redirectURL from '../redirectURL';
import Constants from "../constants";
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYY } from "../common/utils";
// import { createStaticHandler } from '@remix-run/router';
// import BillingTripsData from "./BillingTripsDataGrid";
import CSVFileValidator from 'csv-file-validator';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import Datetime from 'react-datetime';
// import ViewBatchItems from "./viewbatchitems";
import "react-datetime/css/react-datetime.css";
import download from 'js-file-download';
import $ from 'jquery';
//import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import { times } from 'lodash';
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class EpodScreen extends Component {

    state = {
        loadshow: 'show-n',
        overly: 'show-n',
        oRowData: [],
        totalrecords: [],
        pending: [],
        liabilityCnt: [],
        modules: AllModules,
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        isuploadcsv: 0,
        rowData: [],
        // frameworkComponents: {
        //     courierActions: CourierActions,
        //     UploadPOD: UploadPOD,
        //     ViewPOD: ViewPOD,
        //     RevertPOD: RevertPOD
        // },
        defaultColDef: {
            // flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
            initialWidth: 200,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            sortable: true
        },
        detailCellRendererParams: {
            detailGridOptions: {
                columnDefs: [
                    { field: 'callId' },
                    { field: 'direction' },
                    { field: 'number', minWidth: 150 },
                    { field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
                    { field: 'switchCode', minWidth: 150 },
                ],
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.callRecords);
            },
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        supportField: '',
        flTransporter: {
            label: "All",
            value: ""
        },
        filterTransporters: [{
            label: "All",
            value: ""
        }],
        rowSelection: "multiple",
        startDate: moment.parseZone().format('YYYY-MM'),
        delivered_date: '',
        gc_date: '',
        shipment_completion_date: '',
        manual_csvcontent: [],
        manual_file: '',
        gcWayBillList: [],
        shipType: { label: "All", value: "all" },
        multiplePodDocs: [],
        tabOne: "btn-current",
        tabTow: "btn-defaultc",
        tabThree: "btn-defaultc",
        uFile: "",
        dataType: "pending",
        transit_data_for_update: "",
        show_delivered_date: false,
        delivered_date_for_update: '',
        epodDocforupdate: '',
        errMsg: 'd-none',
        totalclosedpods: [],
        invalidDocketRecords: [],
        from_date: moment.parseZone(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
        to_date: moment.parseZone(new Date()).format("YYYY-MM-DD"),
        show: false,
        loadshow: 'show-n',
        overly: 'show-n'


    }

    componentDidMount() {
        this.loadTrips()
    }


    loadTrips = () => {
        // console.log(params,"params123")
        var { from_date, to_date } = this.state
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m'
        })
        redirectURL.post("/consignments/getEnmovilBillingTrips", { from_date, to_date })
            .then((resp) => {
                if (resp.data) {
                    // Replace 'NaN' with an empty string or null before parsing
                    const cleanedData = resp.data.replace(/NaN/g, '""');
                    console.log(cleanedData, "Cleaned response data");

                    try {
                        const parsedData = JSON.parse(cleanedData);
                        const result = parsedData.result;

                        if (result) {
                            const cleanedResult = cleanNaN(result);
                            this.setState({
                                rowData: cleanedResult || [],
                                loadshow: 'show-n',
                                overly: 'show-n'
                            });
                        } else {
                            console.error("Result not found or empty.");
                        }
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                    }
                } else {
                    console.error("No data in response or response is structured differently.");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }




    closeAlert = () => {
        this.setState({
            show: false
        });
    }


    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;

    };

    handlerStartDeliveryDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.to_date != "" && startdate > this.state.to_date) {
            this.setState({
                show: true,
                basicTitle: 'Start date should be less than End date',
                basicType: "danger",
                loadshow: 'show-n',
                startDate: "",
            })
        }
        else {
            this.setState({
                from_date: startdate
            });
        }
    }
    handlerEndDeliveryDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.from_date != "" && edate < this.state.from_date) {
            this.setState({
                show: true,
                basicTitle: 'End date should be greater than Start date',
                basicType: "danger",
                loadshow: 'show-n',
                endDate: "",
            })
        }
        else {
            this.setState({
                to_date: edate
            });
        }
    }

    onSubmitEnmovilBilling = (event) => {
        event.preventDefault()
        this.loadTrips()
    }

    render() {

        let columnDefs = [
            {
                headerName: Constants.TRIP_CONSIGN_NO,
                field: "consignment_code",
                width: 150,
            },
            {
                headerName: Constants.GC_WAY_BILL_NO,
                field: "gc_waybill_no",
                width: 150,
            },
            {
                headerName: Constants.GC_DATE,
                field: "gc_date",
                width: 150,
                valueGetter: function (params) {
                    try {
                        if (params.data.gc_date != undefined && params.data.gc_date != "") {
                            return getHyphenDDMMMYYYYHHMM(params.data.gc_date);
                        }
                    }
                    catch (e) { }
                }
            },
            {
                headerName: Constants.TRIP_TRANSPORTER_CODE,
                field: "transporter_code",
                width: 150,
            },
            {
                headerName: Constants.TRIP_TRANSPORTER_NAME,
                field: "transporter_name",
                width: 180,
            },

            {
                // headerName: Constants.TRIP_CUSTOMER_CODE,
                headerName: "Dealer Code",
                field: "consignee_code",
                width: 180,
            },
            {
                headerName: Constants.TRIP_CUSTOMER_NAME,
                field: "consignee_name",
                width: 180,
            },
            {
                headerName: "Courier Type",
                field: "courier_type",
                width: 120,
            },
            {
                headerName: "Shipping Type",
                field: "shipping_type",
                width: 120,
            },
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row mb-1 ml-1 mr-1">
                    <div className='card col-xl-12 col-md-12'>
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-2">
                                    <label>Start Date </label>
                                    <Datetime value={this.state.from_date}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        name="fromDate"
                                        onChange={this.handlerStartDeliveryDateTime} />
                                </div>
                                <div className="col-2">
                                    <label>End Date </label>
                                    <Datetime
                                        value={this.state.to_date}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'To Date', name: 'toDate', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        name="toDate"
                                        onChange={this.handlerEndDeliveryDateTime} />
                                </div>
                                <div className="form-group col-xl-3 col-lg-3"   >
                                    <button className="btn btn-success mt-30p" type="submit" onClick={this.onSubmitEnmovilBilling.bind(this)}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-xl-12 col-lg-12'>
                        <div className="card">
                            <div className="card-header">
                                <h5 style={{ float: "left" }}>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> Enmovil Billing Trips</span>

                                </h5>

                            </div>
                            <div className="row card-body">
                                <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        headerHeight={50}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        rowSelection={true}
                                        rowClassRules={this.state.rowClassRules}
                                        onRowSelected={this.onRowSelection}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        onCellClicked={this.onCellClicked}
                                    />

                                    <div className={"dataLoadpage " + (this.state.loadshow)}>
                                    </div>
                                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                                    </div>
                                    <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
                                </div>



                            </div>

                        </div>



                    </div>

                </div>

            </div>

        )
    }
}

function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map11.get(key);
        if (!collection) {
            map11.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map11;
}

// Function to clean NaN from result array
function cleanNaN(data) {
    return data.map(item => {
        for (let key in item) {
            if (item[key] === "NaN") {
                item[key] = "";  // Replace NaN with an empty string or another value
            }
        }
        return item;
    });
}