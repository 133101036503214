import React, { Component } from 'react';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import PRTOnTimeChart from './PRTOnTimeChart';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getHyphenDDMMMYYYY } from '../common/utils';
import CountUp from 'react-countup';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const columnwithdefs = [
    {
        headerName: "Transporter Code",
        field: "transporter_code",
        sortable: true,
        filter: true,
        width: 120,
        flex: 1,
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
        sortable: true,
        filter: true,
        width: 150,
        flex: 1,
    },
    {
        headerName: "On Time",
        field: "on_time_records",
        sortable: true,
        filter: true,
        width: 80,
        flex: 1,
    },
    {
        headerName: "Delayed",
        field: "delayed",
        sortable: true,
        filter: true,
        width: 120,
        valueGetter: params => {
            let { on_time_records = 0, total_records = 0 } = params.data
            return total_records - on_time_records || 0
        },
        flex: 1,
    },
    {
        headerName: "Total Shipments",
        field: "total_records",
        sortable: true,
        filter: true,
        width: 120,
        flex: 1,
    },
    {
        headerName: "Air",
        field: "air",
        sortable: true,
        filter: true,
        width: 80,
        flex: 1,
    },
    {
        headerName: "Road",
        field: "road",
        sortable: true,
        filter: true,
        width: 80,
        flex: 1,
    },
    {
        headerName: "Train",
        field: "train",
        sortable: true,
        filter: true,
        width: 80,
        flex: 1,
    },
    {
        headerName: "PTL",
        field: "ptl",
        sortable: true,
        filter: true,
        width: 80,
        flex: 1,
    },
    {
        headerName: "FTL",
        field: "ftl",
        sortable: true,
        filter: true,
        width: 80,
        flex: 1,
    },
];
export default class LrWiseDashboard extends Component {
    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            loadshow: 'show-m',
            overly: 'show-m',
            modules: AllModules,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                autoHeight: true,
                resizable: true
            },
            rowData: [],
            context: { componentParent: this },
            paginationPageSize: 50,
            start_date: "",
            end_date: "",
            selected_lsps: [{ "label": "All", "value": "all" }],
            selected_cities: [{ "label": "All", "value": "all" }],
            selected_specific_state: [],
            lspOptions: [],
            cityOptions: [],
            courierLSPOptions: [],
            courierCityOptions: [],
            firstChartArr: [],
            secondChartArr: [],
            fourthChartArr: [],
            thirdChartArr: [],
            DealerChartArr: [],
            DealerChartArr2: [],
            tptLevelData: [],
            prtCourierActive: "",
            prtActive: "activet",
            prtSurfaceCourierActive: "",
            chartQuery: "",
            showChartData: "show-m",
            showGridData: "show-n",
            gridTab: "",
            chartTab: "activet",
            shipping_type: "",
            transporter_data: [],
            stateList: [{ "value": "all", "label": "All" }],
            shipping_type_tab: "all",
            dealerList: [],
            selected_shipping_type: { label: "All", value: "all" },
            changed_shipping_type: "COURIER",
            current_state_list: [],
            current_transporter_list: [],
            top_on_time_data: [],
            low_on_time_data: [],
            on_time_df: [],
            plantsOptions: [{ label: "All", value: "all" }],
            selected_plant: { label: "All", value: "all" },
            summaryData: {},
            ShippingTypeOptions: [{ label: "All", value: "all" }],
            shippingTypeCounter: {},
        };
        this.getCombinedChartData = this.getCombinedChartData.bind(this);
        this.getInitialData = this.getInitialData.bind(this);
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
        });
    }
    async componentDidMount() {
        const edate = moment.parseZone().format('YYYY-MM-DD');
        const sdate = moment.parseZone(new Date()).subtract(7, 'days').format('YYYY-MM-DD');
        const dateParams = {
            start_date: sdate,
            end_date: edate
        };
        try {
            // Fetch initial data
            const initialResponse = await redirectURL.post("/masters/getinputDatafortptdashboard", dateParams);
            const { stateList: state_list, transporter_data: transporterData, plants_list: plantsData = [], shipping_type_list: ShippingTypeOptions = [] } = initialResponse.data;
            // Prepare plant options and mapping
            const plantsByCode = plantsData.reduce((acc, item) => {
                acc[item.plant_code] = item.plant_name;
                return acc;
            }, {});
            const plantsOptions = plantsData.map(item => ({ label: item.plant_code, value: item.plant_code }));
            plantsOptions.unshift({ label: "All", value: "all" });
            ShippingTypeOptions.unshift({ label: "All", value: "all" });
            // Prepare state and transporter options
            const stateList = [{ value: "all", label: "All" }, ...state_list.map(e => ({ label: e, value: e }))];
            const transporterOptions = [{ value: "all", label: "All" }, ...transporterData.map(b => ({
                value: b.transporter_code,
                label: `${b.transporter_name} - ${b.transporter_code}`
            }))];
            // Set initial state
            this.setState({
                transporter_data: transporterOptions,
                stateList,
                selected_plant: plantsOptions[0],
                plantsOptions,
                start_date: sdate,
                end_date: edate,
                selected_lsps: { "label": "All", "value": "all" },
                ShippingTypeOptions,
            }, async () => {
                await this.getInitialData(dateParams);
                var shippingType = "all"
                const commonChartParams = {
                    from_date: `${sdate} 00:00:00`,
                    to_date: `${edate} 23:59:59`,
                    state_list: "all",
                    transporterData: "all",
                    shipping_type: shippingType,
                };
                if (this.state.selected_plant.value != "all") commonChartParams.consigner_code = this.state.selected_plant.value
                const chartQuery = { ...commonChartParams, api_name: "ShipmentWiseDataCount" };
                // Fetch charts in parallel
                await this.getCombinedChartData(chartQuery);
                this.setState({
                    chartQuery
                });

            });
        } catch (error) {
            console.error("Error during componentDidMount:", error);
        }
    }
    async getInitialData(params) {
        try {
            const response = await redirectURL.post("/masters/getinputDatafortptdashboard", params);
            // Use getOptions method to generate options for LSP and City
            const lspOptions = this.getOptions(response.data.transporter_data);
            const cityOptions = this.getOptions(response.data.stateList);
            const ShippingTypeOptions = this.getOptions(response.data.shipping_type_list)
            // Generate plant options and mapping
            const plantsData = response.data.plants_list || [];
            const plantsOptions = plantsData.map(item => ({
                label: item.plant_code,
                value: item.plant_code
            }));
            plantsOptions.unshift({ label: "All", value: "all" });
            // Set state with all necessary data
            this.setState({
                lspOptions,
                cityOptions,
                plantsOptions,
                selected_plant: plantsOptions[0],
                ShippingTypeOptions: ShippingTypeOptions
            });
        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    }
    async getCombinedChartData(params) {
        try {
            // Fetch data from the API
            const response = await redirectURL.post("/masters/prtOnTimePerformanceChart", params);
            // Parse the response data safely using a helper function
            const parseData = (key) => {
                try {
                    return response.data[key] ? JSON.parse(response.data[key]) : [];
                } catch (error) {
                    console.error(`Error parsing ${key}: `, error);
                    return [];
                }
            };
            const tpt_level = parseData('tpt_df');
            const summaryData = response.data.summary_data || {}
            const shippingTypeCounterData = summaryData.shipping_type_counter || []
            const shippingTypeCounter = {}
            shippingTypeCounterData.map(item => shippingTypeCounter[item.shipping_type] = item.counts)
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                summaryData: summaryData,
                shippingTypeCounter: shippingTypeCounter,
                rowData: tpt_level
            })

        } catch (error) {
            console.error("Error fetching combined chart data:", error);
        }
    }
    async onClickFilterDashboard(type) {
        // Reset state before new data fetch
        this.setState({
            selected_specific_state: type == "main" ? [] : this.state.selected_specific_state,
            tptLevelData: type == "main" ? [] : this.state.tptLevelData,
            loadshow: type == "main" ? "show-m" : "show-n",
            overly: type == "main" ? "show-m" : "show-n",
            rowData: type == "main" ? "" : this.state.rowData,
        });
        const { selected_lsps, selected_cities, selected_shipping_type, selected_plant, start_date, end_date, selected_specific_state } = this.state;
        let transporterData = selected_lsps && selected_lsps.value ? JSON.stringify([selected_lsps.value]) : "";
        if (selected_lsps.value === "all") {
            transporterData = "all"
        }
        let state_list = type == "state" ? this.getSelectedValues(selected_specific_state) || "all" : this.getSelectedValues(selected_cities) || "all";
        if (Array.isArray(state_list)) {
            state_list = JSON.stringify(state_list);
        }
        let shippingType = selected_shipping_type || "all";
        if (Array.isArray(shippingType)) {
            shippingType = shippingType.map(item => item.value)
            if (shippingType.includes("all")) {
                shippingType = "all";  // Update the value of shippingType
            } else {
                shippingType = JSON.stringify(shippingType);  // Convert the array to JSON string
            }
        } else {
            if (shippingType.value === "all") {
                shippingType = "all";
            }
        }
        const queryBase = {
            from_date: `${start_date} 00:00:00`,
            to_date: `${end_date} 23:59:59`,
            state_list: JSON.parse(state_list).length ? state_list : "all",
            transporterData: transporterData || "",
            shipping_type: shippingType,
            consigner_code: selected_plant && selected_plant.value ? selected_plant.value : "all"
        };
        if (type == "state") {
            queryBase.state_wise_cities = 1
        }
        else {
            queryBase.state_wise_cities = 0
        }
        try {
            // Fetch chart data and other grid data only if needed
            const chartQuery = { ...queryBase, api_name: "ShipmentWiseDataCount" };
            const [combinedChartResponse] = await Promise.all([
                this.getCombinedChartData(chartQuery) // Assuming this is always required
            ]);
            // Update the state once all data is fetched
            this.setState({
                chartQuery,
                current_state_list: state_list,
                current_transporter_list: transporterData,
                changed_shipping_type: selected_shipping_type || "all",
                loadshow: "show-n", // Hide loading state after fetching data
                overly: "show-n"
            });
        } catch (error) {
            // More descriptive error message and handle both states
            console.error("Error during onClickFilterDashboard:", error);
            this.setState({
                loadshow: "show-n",
                overly: "show-n"
            });
        }
    }
    getOptions(lst) {
        return [{ label: "ALL", value: "all" }, ...lst.map(e => ({ label: e, value: e }))];
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    onGridReady = (params) => {
        // Store the grid API and column API
        const { api, columnApi } = params;
        this.gridApi = api;
        this.gridColumnApi = columnApi;
        // Update the state with the grid API
        this.setState({
            gridapi: api
        });
    };
    onGridState = () => {
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    handlerGridStartDateTime = (event) => {
        const eventDate = moment(event._d);
        const formattedDate = eventDate.format('YYYY-MM-DD');
        if (!this.state.end_date) {
            this.setState({ start_date: formattedDate });
            return;
        }
        const endDate = moment(this.state.end_date);
        if (eventDate.isAfter(endDate)) {
            this.setState({
                show: true,
                basicTitle: 'Start Date should be less than End Date',
                basicType: "danger",
                loadshow: 'show-n',
                startDate: "",
            });
        } else {
            this.setState({ start_date: formattedDate });
        }
    }
    handlerGridEndDateTime = (event) => {
        const eventDate = moment(event._d);
        const formattedDate = eventDate.format('YYYY-MM-DD');
        if (!this.state.start_date) {
            this.setState({ end_date: formattedDate });
            return;
        }
        const startDate = moment(this.state.start_date);
        if (eventDate.isBefore(startDate)) {
            this.setState({
                show: true,
                basicTitle: 'End Date should be greater than Start Date',
                basicType: "danger",
                loadshow: 'show-n',
                endDate: "",
            });
        } else {
            this.setState({ end_date: formattedDate });
        }
    }
    getSelectedValues(selectedItems) {
        if (!Array.isArray(selectedItems) || selectedItems.some(item => item.value === "all")) {
            return [];
        }
        return selectedItems.filter(item => item.value !== "all").map(item => item.value);
    }
    resetFilters() {
        const edate = moment.parseZone().format('YYYY-MM-DD');
        const sdate = moment.parseZone(new Date()).subtract(7, 'days').format('YYYY-MM-DD');
        this.setState({
            selected_plant: this.state.plantsOptions[0],
            start_date: sdate,
            end_date: edate,
            selected_shipping_type: { label: "All", value: "all" },
            selected_cities: [{ "label": "All", "value": "all" }],
            selected_lsps: { "label": "All", "value": "all" },
        },()=>{
            this.onClickFilterDashboard("main")
        })
    }
    render() {
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row col-xl-12 col-lg-12">
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>Start Date </label>
                        <Datetime
                            value={this.state.start_date}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off', readOnly: true }}
                            dateFormat="YYYY-MM-DD"
                            className='date-z-index'
                            name="startDate"
                            timeFormat={false}
                            onChange={this.handlerGridStartDateTime.bind(this)} />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>End Date </label>
                        <Datetime value={this.state.end_date}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off', readOnly: true }}
                            timeFormat={false}
                            className='date-z-index'
                            dateFormat="YYYY-MM-DD"
                            name="endDate"
                            onChange={this.handlerGridEndDateTime.bind(this)} />
                    </div>
                    <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                        <label>State </label>
                        <Select placeholder={"All"}
                            isMulti={true}
                            value={this.state.selected_cities}
                            className="border-radius-0"
                            onChange={(e) => this.setState({ selected_cities: e })}
                            style={{ borderRadius: "0px" }}
                            options={this.state.stateList} required />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>Transporter </label>
                        <Select placeholder={""} isMulti={false} value={this.state.selected_lsps} className="border-radius-0" onChange={(e) => this.setState({ selected_lsps: e })} style={{ borderRadius: "0px" }} options={this.state.transporter_data} required />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label> Shipping Type </label>
                        <Select placeholder={""} isMulti={true} value={this.state.selected_shipping_type} className="border-radius-0" onChange={(e) => this.setState({ selected_shipping_type: e })} style={{ borderRadius: "0px" }} options={this.state.ShippingTypeOptions} required />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>Plant Code </label>
                        <Select placeholder={"Select Plant"} isMulti={false} value={this.state.selected_plant} className="border-radius-0" onChange={(e) => this.setState({ selected_plant: e })} style={{ borderRadius: "0px" }} options={this.state.plantsOptions} required />
                    </div>
                    <div className="form-group col-md-4 col-xl-2 col-lg-3 d-flex">
                        <div className="form-group col-xl-6 col-lg-6  mr-3">
                            <label>&nbsp;</label><br />
                            <button type="button" className="btn btn-success"
                                onClick={this.onClickFilterDashboard.bind(this, "main")}>Submit</button>
                        </div>
                        <div className="form-group col-xl-6 col-lg-6 ml-3">
                            <label>&nbsp;</label><br />
                            <button type="button" className="btn float-right custom-btn white btn-info" title='Reset Filters' style={{ marginRight: "10px", padding: "7px" }} onClick={this.resetFilters.bind(this)}>
                                <i className="icofont icofont-refresh"></i> Reset
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect" style={{ top: "-20px" }}>
                    <div className="col-xl-12 col-lg-12 d-flex justify-content-end mt-0 pt-0" style={{ top: "14px", right: "10px" }}>
                        <p style={{ color: "#e90505" }}>
                            <span style={{ fontWeight: "900" }}>Note :</span> Data for  Delivered Consignments is being Displayed
                        </p>
                    </div>
                    <div className="card mt-0">
                        <div className="card-body m-0 p-2" id="counterCardBody">
                            {/* <h5 className="header p-2" style={{ textAlign: 'center' }}>
                                Summary Data
                            </h5> */}
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col p-0 m-0">
                                        <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-ui-browser f24 txt-primary"></i><br /> Total</span>
                                        <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.summaryData && this.state.summaryData.total_records !== undefined && this.state.summaryData.total_records !== null && this.state.summaryData.total_records !== ""
                                            ? this.state.summaryData.total_records
                                            : 0)} /></span>
                                        </h4>
                                    </div>
                                    <div className="col  p-0 m-0">
                                        <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-clock-time f22 greenfont "></i><br />On Time</span>
                                        <h4 className=" greenfont f40"><span className="counter"><CountUp end={(this.state.summaryData && this.state.summaryData.on_time_recs !== undefined && this.state.summaryData.on_time_recs !== null && this.state.summaryData.on_time_recs !== ""
                                            ? this.state.summaryData.on_time_recs
                                            : 0)} /></span>
                                        </h4>
                                    </div>
                                    <div className="col  p-0 m-0">
                                        <span className="f13" style={{ paddingTop: "7px" }}>
                                            <i className="icofont icofont-hour-glass f20 redfont"></i>
                                            <br /> Delayed
                                        </span>
                                        <h4 className="redfont f40">
                                            <span className="counter">
                                                <CountUp
                                                    end={
                                                        this.state.summaryData &&
                                                            this.state.summaryData.total_records &&
                                                            this.state.summaryData.on_time_recs
                                                            ? this.state.summaryData.total_records - this.state.summaryData.on_time_recs
                                                            : 0
                                                    }
                                                />
                                            </span>
                                        </h4>
                                    </div>
                                    <div className="col  p-0 m-0">
                                        <span className="f13" style={{ paddingTop: "7px" }}><i className="fa fa-percent f16 txt-secondary"></i><br />On Time Percentage</span>
                                        <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.summaryData && this.state.summaryData.on_time_percentage !== undefined && this.state.summaryData.on_time_percentage !== null && this.state.summaryData.on_time_percentage !== ""
                                            ? this.state.summaryData.on_time_percentage
                                            : 0)} /></span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card mt-0">
                        <div className="card-body" id="counterCardBody">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col p-0 m-0">
                                        <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-road f20 text-information"></i><br /> Road </span>
                                        <h4 className="text-information f40"><span className="counter"><CountUp end={(this.state.shippingTypeCounter && this.state.shippingTypeCounter["05"] !== undefined && this.state.shippingTypeCounter["05"] !== null && this.state.shippingTypeCounter["05"] !== ""
                                            ? this.state.shippingTypeCounter["05"]
                                            : 0)} /></span>
                                        </h4>
                                    </div>
                                    <div className="col p-0 m-0">
                                        <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-airplane f24 bluefont"></i><br /> Air </span>
                                        <h4 className="bluefont f40"><span className="counter"><CountUp end={(this.state.shippingTypeCounter && this.state.shippingTypeCounter["07"] !== undefined && this.state.shippingTypeCounter["07"] !== null && this.state.shippingTypeCounter["07"] !== ""
                                            ? this.state.shippingTypeCounter["07"]
                                            : 0)} /></span>
                                        </h4>
                                    </div>
                                    <div className="col p-0 m-0">
                                        <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-train-line f22 txt-warning"></i><br /> Train </span>
                                        <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.shippingTypeCounter && this.state.shippingTypeCounter["22"] !== undefined && this.state.shippingTypeCounter["22"] !== null && this.state.shippingTypeCounter["22"] !== ""
                                            ? this.state.shippingTypeCounter["22"]
                                            : 0)} /></span>
                                        </h4>
                                    </div>
                                    <div className="col p-0 m-0">
                                        <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-fast-delivery f22 text-muted"></i><br /> PTL </span>
                                        <h4 className="text-muted f40"><span className="counter"><CountUp end={(this.state.shippingTypeCounter && this.state.shippingTypeCounter["48"] !== undefined && this.state.shippingTypeCounter["48"] !== null && this.state.shippingTypeCounter["48"] !== ""
                                            ? this.state.shippingTypeCounter["48"]
                                            : 0)} /></span>
                                        </h4>
                                    </div>
                                    <div className="col p-0 m-0">
                                        <span className="f13" style={{ paddingTop: "7px" }}><i className="icofont icofont-truck-loaded f25 txt-success"></i><br /> FTL </span>
                                        <h4 className="text-success f40"><span className="counter"><CountUp end={(this.state.shippingTypeCounter && this.state.shippingTypeCounter["FTL"] !== undefined && this.state.shippingTypeCounter["FTL"] !== null && this.state.shippingTypeCounter["FTL"] !== ""
                                            ? this.state.shippingTypeCounter["FTL"]
                                            : 0)} /></span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className={"col-xl-12 col-lg-12"} style={{ background: "#fff", padding: "20px 12px" }}>
                        <div className="form-group col-xl-8 col-lg-8">
                            <h5   style={{fontWeight:"bold"}}>Transporters Data</h5>
                        </div>
                        <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham col-xl-12 col-lg-12 "}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithdefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                enableRangeSelection={true}
                            />
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}
